import { enhancedFetch, isServer, UsePaginateData } from 'journey-ui'
import { useContext, useEffect } from 'react'

export function useOrderRefresherBackup(brandHashId: string, orderCardsPaginator: UsePaginateData<OrderCard>) {
  useEffect(() => {
    const t = setInterval(() => {
      enhancedFetch(`/api/brand/${brandHashId}/new-order-count`, {})
        .then(({ new_order_count }) => {
          const newOrderCards = orderCardsPaginator.data.filter((oc) => oc.status === 'new')

          if (newOrderCards.length !== new_order_count) {
            console.log('Refreshing order cards due to new order mismatch.')
            orderCardsPaginator.refresh()
          }
        })
        .catch(console.error)
    }, 120_000)

    return () => clearInterval(t)
  }, [brandHashId, orderCardsPaginator])
}
